import React from "react"
import PropTypes from "prop-types"
import { verbColor } from "./Verb"
import SpecSchema from "./SpecSchema"

const getInsetStyle = (color) => ({
  border: `1px solid ${color}`,
  borderRadius: "4px",
  padding: "0.8rem",
  marginBottom: "1rem",
})

const getStatusStyle = (hasDefinition) => ({
  flex: "1 1 50%",
  marginBottom: hasDefinition ? undefined : 0,
})

const SpecPathResponse = ({ verb, statusCode, description, content }) => {
  const schema = (content[0] || {}).childrenOpenApiSpecSchema
  const hasSchema = schema && schema.length === 1
  return (
    <div style={getInsetStyle(verbColor(verb).normal)}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h5 style={getStatusStyle(hasSchema)}>{statusCode}</h5>
        <p style={getStatusStyle(hasSchema)}>{description}</p>
      </div>
      {hasSchema && (
        <div style={getInsetStyle("#ccc")}>
          <SpecSchema schema={schema[0]} />
        </div>
      )}
    </div>
  )
}

SpecPathResponse.propTypes = {
  verb: PropTypes.string.isRequired,
  statusCode: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  content: PropTypes.array,
}

export default SpecPathResponse
