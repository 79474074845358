import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Link from "gatsby-link"
import groupBy from "lodash.groupby"

import SpecInformation from "../components/SpecInformation"
import SpecPaths from "../components/SpecPaths"
import Layout from "../components/layout"

class Api extends Component {
  render() {
    const api = this.props.data.openApiSpec
    const paths = api.childrenOpenApiSpecPath
    const pathGroups = groupBy(paths, (p) => p.tag)

    return (
      <Layout>
        <p>
          <Link to="/">Back</Link>
        </p>
        <SpecInformation
          title={api.title}
          version={api.version}
          description={api.description}
        />
        {Object.keys(pathGroups).map((t) => (
          <SpecPaths key={t} tag={t} paths={pathGroups[t]} />
        ))}
      </Layout>
    )
  }
}

Api.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Api

export const query = graphql`
  query ApiQuery($id: String!) {
    openApiSpec(id: { eq: $id }) {
      name
      title
      description
      version
      childrenOpenApiSpecPath {
        id
        name
        verb
        summary
        description
        tag
        childOpenApiSpecRequestBody {
          description
          childrenOpenApiSpecContent {
            id
            contentType
            childrenOpenApiSpecSchema {
              id
              type
              name
              properties {
                description
                format
                maxLength
                minLength
                name
                nullable
                required
                type
                example
                hasExample
              }
            }
          }
        }
        childrenOpenApiSpecParameter {
          id
          type
          required
          name
          in
          description
        }
        childrenOpenApiSpecResponse {
          id
          statusCode
          description
          childrenOpenApiSpecContent {
            id
            contentType
            childrenOpenApiSpecSchema {
              id
              name
              type
              properties {
                description
                format
                maxLength
                minLength
                name
                nullable
                required
                type
                example
                hasExample
              }
            }
          }
        }
      }
    }
  }
`
