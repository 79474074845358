import { Fragment, useState, useEffect, createElement } from "react"
import PropTypes from "prop-types"
import { unified } from "unified"
import remarkParse from "remark-parse"
import remarkRehype from "remark-rehype"
import rehypeReact from "rehype-react"

const Markdown = ({ markdown }) => {
  const [Content, setContent] = useState(Fragment)
  useEffect(() => {
    unified()
      .use(remarkParse)
      .use(remarkRehype, { fragment: true })
      .use(rehypeReact, { createElement, Fragment })
      .process(markdown)
      .then((file) => {
        setContent(file.result)
      })
  }, [markdown])

  return Content
}

Markdown.propTypes = {
  markdown: PropTypes.string.isRequired,
}

export default Markdown
