import React from "react"
import PropTypes from "prop-types"
import _unescape from "lodash/unescape"
import Markdown from "./markdown"
import SpecExample from "./SpecExample"

const SpecSchemaProperty = ({ name, type, description, format }) => (
  <tr>
    <td>{name}</td>
    <td>{type}</td>
    <td>
      <Markdown markdown={_unescape(description)} />
    </td>
    <td>{format}</td>
  </tr>
)

SpecSchemaProperty.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  description: PropTypes.string,
  format: PropTypes.string,
}

const SpecSchema = ({ schema }) => (
  <div>
    <SpecExample schema={schema} includeAll />
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Description</th>
          <th>Format</th>
        </tr>
      </thead>
      <tbody>
        {schema.properties.map((p, i) => (
          <SpecSchemaProperty
            key={`property-${i}`}
            name={p.name}
            type={p.type}
            description={p.description}
            format={p.format}
          />
        ))}
      </tbody>
    </table>
  </div>
)

SpecSchema.propTypes = {
  schema: PropTypes.object.isRequired,
}

export default SpecSchema
