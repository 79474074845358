import React from "react"
import PropTypes from "prop-types"
import Markdown from "./markdown"

const superScriptStyle = {
  position: "relative",
  top: "-0.5em",
  fontSize: "0.6rem",
  color: "rgba(255,0,0,.6)",
  marginLeft: "1em",
}

const SpecPathParameter = ({
  name,
  source,
  description,
  type,
  format,
  required,
}) => {
  return (
    <tr>
      <td>
        <p style={{ marginBottom: "0.5rem", whiteSpace: "nowrap" }}>
          <code>{name}</code>
          {required && <span style={superScriptStyle}>* required</span>}
        </p>
        {type && <p style={{ fontWeight: 600, marginBottom: 0 }}>{type}</p>}
        {source && (
          <p style={{ marginBottom: "0.5rem" }}>
            <em>({source})</em>
          </p>
        )}
      </td>
      <td>{description && <Markdown markdown={description} />}</td>
    </tr>
  )
}

SpecPathParameter.propTypes = {
  name: PropTypes.string.isRequired,
  source: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  format: PropTypes.string,
  required: PropTypes.bool,
}

const SpecPathParameters = ({ parameters, body }) => {
  const schemas = body && body.childrenOpenApiSpecSchema

  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {parameters.map((p, i) => {
          return (
            <SpecPathParameter
              key={`parameter-${i}`}
              name={p.name}
              source={p.in}
              description={p.description}
              type={p.type}
              format={p.format}
              required={p.required}
            />
          )
        })}
        {schemas &&
          schemas.length === 1 &&
          schemas[0].properties.map((s, i) => (
            <SpecPathParameter
              key={`parameter-${i}`}
              name={s.name}
              source="body"
              description={s.description}
              type={s.type}
              format={s.format}
              required={s.required}
            />
          ))}
      </tbody>
    </table>
  )
}

SpecPathParameters.propTypes = {
  parameters: PropTypes.array.isRequired,
}

export default SpecPathParameters
