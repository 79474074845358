import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Markdown from "./markdown"
import Permalink from "./permalink"
import SpecPathResponse from "./SpecPathResponse"
import SpecPathParameters from "./SpecPathParameters"
import SpecExample from "./SpecExample"

import "./spec.css"
import PathDisplay from "./PathDisplay"

const SpecPath = ({ path }) => {
  const responses = path.childrenOpenApiSpecResponse
  const parameters = path.childrenOpenApiSpecParameter
  const body = path.childOpenApiSpecRequestBody
  let jsonBody = null
  let requestBodySchema = null

  if (body) {
    jsonBody =
      body.childrenOpenApiSpecContent.find(
        (c) => c.contentType === "application/json"
      ) || {}

    const schemas = jsonBody.childrenOpenApiSpecSchema
    requestBodySchema = (schemas && schemas.length === 1 && schemas[0]) || {}
  }

  const href = (path.summary || `${Math.floor(Math.random() * 1000 + 1)}`)
    .toLowerCase()
    .replace(/[^a-z0-9]/gi, "-")

  return (
    <article id={path.operationId} className="spec-path">
      <header>
        <h3 id={href} style={{ marginLeft: "auto" }}>
          <Permalink href={href} name={path.summary} />
          {path.summary}
        </h3>
        <PathDisplay {...path} />
      </header>
      {path.description && <Markdown markdown={path.description} />}
      {jsonBody && (
        <Fragment>
          <h4 id={`${href}-example`}>
            <Permalink
              href={`${href}-example`}
              name={`${path.summary} Example Request`}
            />
            Example Request
          </h4>
          <SpecExample schema={requestBodySchema} />
        </Fragment>
      )}
      {parameters && (
        <Fragment>
          <h4 id={`${href}-parameters`}>
            <Permalink
              href={`${href}-parameters`}
              name={`${path.summary} Parameters`}
            />
            Parameters
          </h4>
          <SpecPathParameters parameters={parameters} body={jsonBody} />
        </Fragment>
      )}
      <h4 id={`${href}-responses`}>
        <Permalink
          href={`${href}-responses`}
          name={`${path.summary} Responses`}
        />
        Responses
      </h4>
      {responses &&
        responses.map((r) => (
          <SpecPathResponse
            key={r.id}
            verb={path.verb}
            statusCode={r.statusCode}
            description={r.description}
            content={r.childrenOpenApiSpecContent}
          />
        ))}
    </article>
  )
}

SpecPath.propTypes = {
  path: PropTypes.object.isRequired,
}

const SpecPaths = ({ tag, paths }) => {
  const href = tag.toLowerCase().replace(/[^a-z0-9]/gi, "-")
  return (
    <section className="spec-path-group">
      <h2 id={href}>
        <Permalink href={href} name={tag} />
        {tag}
      </h2>
      {paths.map((p) => (
        <SpecPath path={p} key={`${p.name}-${p.verb}`} />
      ))}
    </section>
  )
}

SpecPaths.propTypes = {
  tag: PropTypes.string.isRequired,
  paths: PropTypes.array.isRequired,
}

export default SpecPaths
