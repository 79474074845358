import React, { Fragment } from "react"
import PropTypes from "prop-types"

import "./example.css"

const classMap = {
  string: "s2",
  boolean: "kc",
  number: "na",
  integer: "na",
  array: "p",
}

const computeClass = (type) => classMap[type] || "nx"

const SpecPathExample = ({ schema, includeAll = false }) => {
  let exampleValues = []

  schema.properties.forEach((sp) => {
    if (includeAll || sp.required || sp.hasExample) {
      exampleValues.push(sp)
    }
  })

  if (exampleValues.length === 0) {
    return <span />
  }

  const exampleBody = exampleValues.map((v, i) => (
    <Fragment key={v.name}>
      <span className="s1">"{v.name}"</span>
      <span className="p">:</span>
      <span className="w"> </span>
      <span className={v.example === "null" ? "p" : computeClass(v.type)}>
        {v.example}
      </span>
      {i === exampleValues.length - 1 ? (
        <span className="w">{"\n"}</span>
      ) : (
        <Fragment>
          <span className="p">,</span>
          <span className="w">{"\n   "}</span>
        </Fragment>
      )}
    </Fragment>
  ))

  return (
    <pre className="highlight json">
      <code>
        <span className="p">{"{"}</span>
        <span className="w">{exampleBody.length > 0 ? "\n   " : "\n"}</span>
        {exampleBody}
        <span className="p">{"}"}</span>
      </code>
    </pre>
  )
}

SpecPathExample.propTypes = {
  schema: PropTypes.object.isRequired,
  includeAll: PropTypes.bool,
}

export default SpecPathExample
